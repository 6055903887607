export default class Styleguide {

    static postHeight(delay = 0) {
        setTimeout(() => {
            let url = window.location.pathname;

            if (frameElement) {
                url = frameElement.src.replace(window.parent.location.origin, '');
            }

            parent.postMessage(JSON.stringify({
                url,
                height: document.body.offsetHeight
            }), '*');
        }, delay);
    }
}
