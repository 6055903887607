import { ComponentLoader } from 'sets/shop';


let instance;

export default class MessageQueue {
    constructor() {
        if (!instance) {
            instance = this;

            this.queue = [];

            this._initEventHandlers();
        }

        return instance;
    }

    addMessage(message) {
        this.queue.push(message);

        this._emptyQueue();

        return this;
    }


    async _initEventHandlers() {
        const Toast = await ComponentLoader.loadByName('Toast');

        $('body').on(Toast.SHOW_MESSAGE, (event, message) => {
            this.addMessage(message);
            event.stopImmediatePropagation();
        });

        return this;
    }

    setToast(toast) {
        this.toast = toast;

        this._emptyQueue();

        return this;
    }

    _emptyQueue() {
        while (this.toast && this.queue.length) {
            const message = this.queue.pop();

            this.toast.add(message);
        }

        return this;
    }
}
