import SessionStorage from './session-storage';

export default class History {
    constructor() {
        this.sessionStorage = new SessionStorage();
    }

    updateHistory() {
        const sessionStorage = new SessionStorage();

        const history = sessionStorage.getItem('kfz.history') || [];
        const url = window.location.href;

        if (history.length > 0 && history[history.length - 1] === url) {
            return;
        }

        history.push(url);

        if (history.length > 2) {
            sessionStorage.setItem('kfz.history', history.slice(history.length - 2, history.length));
        } else {
            sessionStorage.setItem('kfz.history', history);
        }
    }
}
