import isMobile from 'ismobilejs';

export default class DeviceHelper {

    static isMobile() {
        return isMobile.any;
    }

    static isIPhone() {
        return isMobile.apple.phone || isMobile.apple.ipod;
    }

    static isIosSafari() {
        const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/); // eslint-disable-line no-useless-escape
        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        return (isSafari && iOS);
    }

}
