import LocalStorage from '../modules/local-storage';
import Component from './component';


const FORM_PREFIX = 'kfz.forms';
const FORM_NAME_FALLBACK = 'global';

export default class FormField extends Component {
    constructor(config) {
        super(config);

        if (this.constructor === FormField.constructor) {
            throw new TypeError('Must not instanciate abstract class FormField');
        }

        const interFace = ['getName', 'getValue', 'setValue', 'enable', 'disable', 'shouldIntercept'];
        for (const method of interFace) {
            if (typeof this[method] === 'undefined') {
                throw new TypeError(`Method "${method}" must be implemented in component "${this.constructor.name}".`);
            }
        }

        this._form = config.form || {};

        this._storage = new LocalStorage();
    }

    set form(form) {
        this._form = form;

        this._restore();
    }

    get _storageKey() {
        if (this._form.persist) {
            const formName = this._form.name ? this._form.name : FORM_NAME_FALLBACK;
            const fieldName = this.getName();

            if (fieldName) {
                return `${FORM_PREFIX}.${formName}.${fieldName}`;
            }
        }

        return null;
    }

    _persist() {
        if (this._storageKey) {
            this._storage.setItem(this._storageKey, this.getValue());
        }
    }

    _restore() {
        if (this._storageKey) {
            const data = this._storage.getItem(this._storageKey);

            if (data !== null && data !== '' && (this.getValue() === '' || typeof this.getValue() === 'undefined')) {
                this.setValue(data);
            }
        }
    }

    _handleInvalidEvent(event) {
        const visible = this.$element.is(':visible');
        if (!visible) {
            event.preventDefault();
        }

        this.$element.trigger({
            type: FormField.INVALID, parameters: {
                field: this,
                visible
            }
        });
    }


    static get INVALID() {
        return 'FORM_FIELD__INVALID';
    }

    static get INITIALIZED() {
        return 'FORM_FIELD__INITIALIZED';
    }
}
