import map from 'lodash/map';

export default class UrlHelper {
    static getUrlParameters(url = window.location.href) {
        const parameterString = url
            .split('#')[0]
            .split('?')[1] || '';

        return parameterString
            .split('&')
            .reduce((params, parameter) => {
                const [ key, value ] = parameter.split('=');
                if (key) {
                    params[key] = value || '';
                }

                return params;
            }, {});
    }

    static assembleUrl({ url = window.location.href, parameters = {}, shouldMerge = true }) {
        let fragment;

        if (shouldMerge) {
            parameters = Object.assign(UrlHelper.getUrlParameters(url), parameters);
        }

        if (~url.indexOf('#')) {
            fragment = url.split('#')[1];
            url = url.split('#')[0];
        }

        const mappedParameters = map(parameters, (value, key) => {
            if (Array.isArray(value)) {
                return map(value, (valueItem) => {
                    return `${key}%5B%5D=${valueItem}`;
                }).join('&');
            }

            return `${key}=${value}`;
        });

        url = url.split('?')[0];
        if (mappedParameters.length) {
            url += '?' + mappedParameters.join('&');
        }

        return `${url}${fragment ? `#${fragment}` : ''}`;
    }

    static isHomePage() {
        return location.pathname === '/';
    }

    static getCarSelectionURL({ url, parameters }, force = false) {
        let obj = {
            parameters
        };

        if (UrlHelper.isHomePage() || force) {
            obj.url = url;
        }

        return UrlHelper.assembleUrl(obj);
    }
}
