import has from 'lodash/has';

import { AjaxHandler as BaseAjaxHandler, CssHelper } from 'commons';
import { ComponentLoader } from 'sets/shop';


let instance;

export default class AjaxHandler extends BaseAjaxHandler {
    constructor(config) {
        super(config);

        if (!instance) {
            instance = this;

            this._initEventHandlers();
        }

        return instance;
    }

    _handleResponse(event, request, options, response) {
        const { partials = {} } = response;

        const canHandleResponse = Object.keys(partials)
            .reduce((canHandle, key) => {
                const { version } = this._extractInfoFromHTML(partials[key].html || '');
                const handlerVersion = CssHelper.getComponentVersion();

                return canHandle && version === handlerVersion;
            }, true);

        if (canHandleResponse) {
            event.stopImmediatePropagation();
            super._handleResponse(event, request, options, response);

            if (has(response, 'partials.info_dialog')) {
                this._initInfoDialog(response.partials.info_dialog.html);
            }
        }
    }

    async _initInfoDialog(html) {
        html = AjaxHandler.removeLeadingClosingFormFromHTML(html);

        const { version } = this._extractInfoFromHTML(html);
        const handlerVersion = CssHelper.getComponentVersion();

        if (version === handlerVersion) {
            const TheDialog = await ComponentLoader.loadByName('TheDialog');
            TheDialog.createFromHtml({ html });
        }
    }

    _displayMessages(messages) {
        messages.forEach((message) => this.messageQueue.addMessage(message));
    }

    async _updatePartial($partial, html) {
        const { component, version } = this._extractInfoFromHTML(html);
        const handlerVersion = CssHelper.getComponentVersion();

        if (version === handlerVersion) {
            const $html = $(html);
            $partial.replaceWith($html);

            const ComponentClass = await ComponentLoader.loadByName(component, true);
            if (ComponentClass) {
                new ComponentClass({
                    baseClass: CssHelper.getBaseClass(component),

                    element: $html
                }).init();
            }
        } else {
            throw new Error(`Incompatible versions: HTML (${version}) - Handler (${handlerVersion})`);
        }
    }

    async _initComponent(event, $element) {
        const html = $element.html();

        const { component, version } = this._extractInfoFromHTML(html);

        if (version === CssHelper.getComponentVersion()) {
            const ComponentClass = await ComponentLoader.loadByName(component);

            if (ComponentClass) {
                new ComponentClass({
                    baseClass: CssHelper.getBaseClass(component),
                    element: $element
                }).init();
            }
        }
    }

    // TODO: this function should be removed after the "</form>" rendering fix for IE11 and iOS10 is properly replaced
    static removeLeadingClosingFormFromHTML(html) {
        return html.trim()
            .replace(/^<\/form>/, '');
    }
}
