const getPromise = (returnValue) => {
    return (returnValue && returnValue.then) ? returnValue : Promise.resolve();
};

export default class Component {
    constructor({ element, baseClass }) {
        Object.assign(this, { baseClass });

        if ($(element).data('instance')) {
            return $(element).data('instance');
        }

        this.$element = $(element);

        this.$element.data('instance', this);

        this._initPromise = null;
    }

    init() {
        const promise = new Promise(async (resolve) => {
            if (!this._initPromise) {
                this._initPromise = getPromise(this._initComponent());
            }

            this._initPromise.then(async () => {
                await this._initDone();

                resolve();
            });
        });

        return promise;
    }

    _initComponent() {
    }

    _initDone() {
    }

    data(name, value) {
        if (value) {
            this.$element.data(name, value);
        }

        return this.$element.data(name);
    }

    findElement(element) {
        return this.$element.find(`.js-${this.baseClass}__${element}`);
    }

    toggleModifier(modifier, setActive) {
        const classString = `${this.baseClass}--${modifier}`;

        if (typeof setActive !== 'undefined') {
            this.$element.toggleClass(classString, setActive);
        } else {
            this.$element.toggleClass(classString);
        }


    }
}
