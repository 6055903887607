/* global VERSION */

const PREFIX = 'kfz';

export default class CssHelper {
    static getBaseClass(componentName) {
        return `${PREFIX}${componentName}-${VERSION}`;
    }

    static getModifierClassNames(baseClass, modifiers) {
        return modifiers
            .map((modifier) => {
                return `${baseClass}--${modifier}`;
            })
            .join(' ');
    }

    static prefix(rules) {
        for (let key of Object.keys(rules)) {
            const value = rules[key];

            rules['-webkit-' + key] = value;
            rules['-moz-' + key] = value;
            rules['-ms-' + key] = value;
        }

        return rules;
    }

    static kebapToCamelCase(string, capitalize = false) {
        string = string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

        if (capitalize) {
            return CssHelper.capitalize(string);
        }

        return string;
    }

    static capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static getComponentVersion() {
        return VERSION;
    }
}
