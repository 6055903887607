import { LocalStorage, TrackingHandler } from 'commons';
import has from 'lodash/has';

export default class AjaxHandler {
    constructor({ messageQueue } = {}) {
        this.localStorage = new LocalStorage();
        this.messageQueue = messageQueue;

        if (this.constructor === AjaxHandler) {
            throw new TypeError('Must not instanciate abstract class AjaxHandler');
        }

        const interFace = ['_displayMessages'];
        for (const method of interFace) {
            if (typeof this[method] === 'undefined') {
                throw new TypeError(`Method "${method}" must be implemented in component "${this.constructor.name}".`);
            }
        }
    }

    _initEventHandlers() {
        $(document)
            .ajaxSuccess(this._handleResponse.bind(this))
            .ajaxError(this._handleError.bind(this));

        $('body').on(AjaxHandler.INIT_COMPONENT, (event, { $element }) => {
            this._initComponent(event, $element);
        });
    }

    _handleResponse(event, request, options, response) {
        if (response.partials) {
            this._updatePartials(response.partials);
        }

        if (window.store && has(response, ['data', 'minicart'])) {
            window.store.commit('miniCart/MINICART_UPDATE', response.data);
        }

        if (window.store && has(response, ['data', 'wishlist'])) {
            window.store.commit('wishlist/WISHLIST_UPDATE', response.data);
        }

        if (window.store && has(response, ['messages'])) {
            window.store.dispatch('messages/addMessages', response.messages);
        }

        const shouldRedirect = options && options.data && !options.data.includes('ignoreRedirect=true');

        if (response.redirect) {
            if (!options.data || shouldRedirect) {
                window.location.href = response.redirect;
            }
        }

        if (response.messages) {
            this._displayMessages(response.messages);
        }

        if (response.tracking) {
            this._pushTrackingData(response.tracking);
        }
    }

    _handleError(event, jqXHR) {
        event.stopImmediatePropagation();

        const isNotAborted = !!jqXHR.getAllResponseHeaders();

        if (isNotAborted) {
            window.store.dispatch('messages/showAjaxError', '');
        }
    }

    async _updatePartials(partials) {
        const promises = Object.keys(partials)
            .filter((partialId) => partials[partialId].html)
            .map(async (partialId) => {
                const partial = partials[partialId];

                const $partial = $(document).find(`#partial-${partialId}`);

                if ($partial.length) {
                    return await this._updatePartial($partial, partial.html);
                }

                return Promise.resolve();
            });

        return Promise.all(promises);
    }

    _pushTrackingData(data) {
        TrackingHandler.track({ data });
    }

    _extractInfoFromHTML(html) {
        const regex = /class="kfz([\w]+)-(\d+)/;
        const [, component, version] = html.match(regex);

        return { component, version };
    }


    static get INIT_COMPONENT() {
        return 'INIT_COMPONENT';
    }
}
