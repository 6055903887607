import de from './pikaday/de.json';
import en from './pikaday/en.json';
import la from './pikaday/la.json';
import nl from './pikaday/nl.json';
import fr from './pikaday/fr.json';

const FALLBACK_LOCALE = 'de';

const localizations = {
    pikaday: { de, en, la, nl, fr }
};

export default {
    getLocale(fallback) {
        return document.querySelector('html').lang || fallback || FALLBACK_LOCALE;
    },

    getLocalization(domain) {
        return localizations[domain][this.getLocale()];
    }
};
