import Component from './component';


export default class LayerComponent extends Component {
    constructor(config) {
        super(config);
    }

    _getKeyDownHandler(closeCallback) {
        return (event) => {
            if (event.which === 27) {
                closeCallback();
                event.stopPropagation();
                event.preventDefault();
            }
        };
    }

    _isOuterClick($element, event) {
        return !$.contains($element[0], event.target) && !$element.is(event.target);
    }

    _getOuterClickHandler($element, closeCallback) {
        return (event) => {
            if (this._isOuterClick($element, event)) {
                closeCallback.call(this);
                event.preventDefault();
                event.stopPropagation();
            }
        };
    }
}
