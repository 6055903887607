const WIDTH = {
    XS: { FROM: 0,   TO: 320 },
    S:  { FROM: 321, TO: 480 },
    M:  { FROM: 481, TO: 768 },
    L:  { FROM: 769, TO: 1000 },
    XL: { FROM: 1001 }
};

const MEDIA = {
    XS: {
        UP: `(min-width: ${WIDTH.XS.FROM}px)`,
        DOWN: `(max-width: ${WIDTH.XS.TO}px)`
    },
    S: {
        UP: `(min-width: ${WIDTH.S.FROM}px)`,
        DOWN: `(max-width: ${WIDTH.S.TO}px)`

    },
    M: {
        UP: `(min-width: ${WIDTH.M.FROM}px)`,
        DOWN: `(max-width: ${WIDTH.M.TO}px)`
    },
    L: {
        UP: `(min-width: ${WIDTH.L.FROM}px)`,
        DOWN: `(max-width: ${WIDTH.L.TO}px)`
    },
    XL: {
        UP: `(min-width: ${WIDTH.XL.FROM}px)`
    }
};

MEDIA.XS.RANGE = `${MEDIA.XS.UP} and ${MEDIA.XS.DOWN}`;
MEDIA.S.RANGE  = `${MEDIA.S.UP} and ${MEDIA.S.DOWN}`;
MEDIA.M.RANGE  = `${MEDIA.M.UP} and ${MEDIA.M.DOWN}`;
MEDIA.L.RANGE  = `${MEDIA.L.UP} and ${MEDIA.L.DOWN}`;


export default class BreakpointHelper {
    static isMobile() {
        return window.matchMedia(MEDIA.S.DOWN).matches;
    }

    static isTablet() {
        return window.matchMedia(MEDIA.M.RANGE).matches;
    }

    static isDesktop() {
        return window.matchMedia(MEDIA.L.UP).matches;
    }


    static get WIDTH() {
        return WIDTH;
    }

    static get MEDIA() {
        return MEDIA;
    }
}
