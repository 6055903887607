let instance;

export default class MemoryStorage {

    constructor() {
        if (!instance) {
            instance = this;
            this.storage = {};
        }

        return instance;
    }

    setItem(key, value) {
        if (typeof key === 'undefined' || typeof value === 'undefined') {
            return;
        }

        this.storage[key] = value;
    }

    getItem(key) {
        if (typeof key === 'undefined') {
            return null;
        }

        return this.storage[key];
    }

    hasItem(key) {
        if (typeof key === 'undefined') {
            return false;
        }

        return typeof this.storage[key] !== 'undefined';
    }

    removeItem(key) {
        if (typeof key === 'undefined') {
            return;
        }

        if (this.hasItem(key)) {
            delete this.storage[key];
        }
    }
}
