import { CssHelper } from 'commons';


export default class ComponentLoader {

    static getLoadFunctions() {
        throw new Error('Must be implemented by child class');
    }

    static loadAndInitAll() {
        return Promise.all(
            Object
                .keys(ComponentLoader.getLoadFunctions())
                .map((baseClass) => ComponentLoader.loadAndInitByBaseClass(baseClass))
        );
    }

    static loadAndInitByName(name, $root = $(document)) {
        const baseClass = CssHelper.getBaseClass(name);

        return ComponentLoader.loadAndInitByBaseClassMultiple([baseClass], $root);
    }

    static loadAndInitByNameMultiple(names, $root = $(document)) {
        const baseClasses = names.map((name) => CssHelper.getBaseClass(name));

        const promise = ComponentLoader.loadAndInitByBaseClassMultiple(baseClasses, $root);
        return promise;
    }

    static loadAndInitByBaseClass(baseClass, $root = $(document)) {
        return ComponentLoader.loadAndInitByBaseClassMultiple([baseClass], $root);
    }

    static loadAndInitByBaseClassMultiple(baseClasses, $root = $(document)) {
        const promises = baseClasses
            .map((baseClass) => {
                const $elements = $root.find(`.${baseClass}`);

                if ($elements.length) {
                    const promise = new Promise(async (resolve) => {
                        const Component = await ComponentLoader.loadByBaseClass(baseClass);
                        await ComponentLoader.findAndInitVersion(baseClass, $root, Component);
                        resolve();
                    });

                    return promise;
                }

                return Promise.resolve();
            });

        return Promise.all(promises);
    }


    static loadByName(name, optional = false) {
        const baseClass = CssHelper.getBaseClass(name);

        return ComponentLoader.loadByBaseClass(baseClass, optional);
    }

    static loadByNameMultiple(names) {
        const promises = names.map((name) => {
            return ComponentLoader.loadByName(name);
        });

        return Promise.all(promises);
    }

    static loadByBaseClass(baseClass, optional = false) {


        if (ComponentLoader.getLoadFunctions()[baseClass]) {
            return ComponentLoader.getLoadFunctions()[baseClass]();
        }

        if (!optional) {
            throw new Error(`Module "${baseClass}" could not be loaded.`);
        }

        return Promise.resolve();
    }

    static findAndInitVersion(baseClass, $root = $(document), Component) {
        let $elements = $root.find(`.js-${baseClass}`);

        const promises = $elements.toArray().map((element) => {
            const instance = new Component(Object.assign({ element, baseClass }));
            return instance.init();
        });

        return Promise.all(promises);
    }

}
