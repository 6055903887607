const eventHandlers = {};

let instance;

export default class EventHub {
    constructor() {
        if (!instance) {
            instance = this;

            const $body = $('body');

            const instances = $body.data('eventhub-instances') || [];
            instances.push(instance);

            $body.data('eventhub-instances', instances);
        }

        return instance;
    }

    addEventHandler(eventName, object, callback) {
        if (typeof eventName === 'string' && typeof callback === 'function') {
            if (!eventHandlers[eventName]) {
                eventHandlers[eventName] = new Map();
            }

            eventHandlers[eventName].set(object, callback);
        }
    }

    removeEventHandler(event, object) {
        if (eventHandlers[event]) {
            eventHandlers[event].delete(object);
        }
    }

    broadcast(event, data, source, preventBroadcasting = false) {
        this._handleEvent(event, data);

        if (!preventBroadcasting) {
            const $body = $('body');
            const instances = $body.data('eventhub-instances') || [];

            instances
                .filter((hubInstance) => hubInstance !== instance)
                .forEach((hubInstance) => hubInstance.broadcast(event, data, source, true));
        }
    }

    _handleEvent(event, data) {
        const handlers = eventHandlers[event];

        if (handlers) {
            // eslint-disable-next-line no-unused-vars
            handlers.forEach((callback, object) => {
                callback(event, data);
            });
        }
    }
}
