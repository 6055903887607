import 'babel-polyfill';

import BreakpointHelper from './js/helpers/breakpoint';
import CssHelper from './js/helpers/css';
import DeviceHelper from './js/helpers/device';
import KeyHelper from './js/helpers/key';
import ScriptHelper from './js/helpers/script';
import ScrollingHelper from './js/helpers/scrolling';
import Styleguide from './js/helpers/styleguide';
import UrlHelper from './js/helpers/url';

import AjaxHandler from './js/modules/ajax-handler';
import EventHub from './js/modules/event-hub';
import History from './js/modules/history';
import InterceptorMessage from './js/modules/interceptor-message';
import TrackingHandler from './js/modules/tracking-handler';

import LocalStorage from './js/modules/local-storage';
import MemoryStorage from './js/modules/memory-storage';
import SessionStorage from './js/modules/session-storage';
import ComponentLoader from './js/modules/component-loader';
import IntersectionObserver from './js/modules/intersection-observer';

import Component from './js/components/component';
import FormField from './js/components/form-field';
import LayerComponent from './js/components/layer-component';

import i18n from './js/i18n';

import * as modules0 from './js/modules/ajax-handler.js'; import * as modules1 from './js/modules/component-loader.js'; import * as modules2 from './js/modules/event-hub.js'; import * as modules3 from './js/modules/history.js'; import * as modules4 from './js/modules/interceptor-message.js'; import * as modules5 from './js/modules/intersection-observer.js'; import * as modules6 from './js/modules/local-storage.js'; import * as modules7 from './js/modules/memory-storage.js'; import * as modules8 from './js/modules/session-storage.js'; import * as modules9 from './js/modules/synthetic-click.js'; import * as modules10 from './js/modules/tracking-handler.js'; let modules = [modules0, modules1, modules2, modules3, modules4, modules5, modules6, modules7, modules8, modules9, modules10];

export {
    BreakpointHelper,
    CssHelper,
    DeviceHelper,
    KeyHelper,
    ScriptHelper,
    ScrollingHelper,
    Styleguide,
    UrlHelper,

    AjaxHandler,
    EventHub,
    History,
    InterceptorMessage,
    TrackingHandler,

    LocalStorage,
    MemoryStorage,
    SessionStorage,
    ComponentLoader,
    IntersectionObserver,

    Component,
    FormField,
    LayerComponent,

    i18n,

    modules
};
