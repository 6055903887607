import 'intersection-observer';

let instance;

export default class CustomIntersectionObserver {
    constructor() {
        if (!instance) {
            instance = this;

            this.observables = new Map();
            this.observer = new IntersectionObserver(this.onChange.bind(this));
        }

        return instance;
    }

    onChange(changes) {
        changes.forEach((change) => {
            const element = change.target;
            if (this.observables.get(element)) {
                const { callback, options } = this.observables.get(element);

                if (change.isIntersecting) {
                    callback.call(null, change);

                    if (options.once === true) {
                        this.unobserve(element);
                    }
                }
            }
        });
    }

    observe(element, callback, options) {
        this.observables.set(element, { callback, options });
        this.observer.observe(element);
    }

    unobserve(element, callback, options) {
        this.observables.delete(element, { callback, options });
        this.observer.unobserve(element);
    }
}
