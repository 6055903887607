/* global jQuery */
import BreakpointHelper from './breakpoint';


export default class ScrollingHelper {
    static getHeaderHeight() {

        const $header = $('#partial-header');
        const header = $header.data('instance');

        if (header) {
            return header.getHeight();
        }

        return 0;
    }

    static getScrollOffset() {
        if (BreakpointHelper.isMobile()) {
            return ScrollingHelper.SCROLL_OFFSET.MOBILE;
        }

        return ScrollingHelper.SCROLL_OFFSET.DESKTOP;
    }

    static scrollToElement($element, timeout = 300) {
        const element = $element instanceof jQuery ? $element[0] : $element;

        setTimeout(() => element.scrollIntoView({ behavior: 'smooth' }), timeout);
    }

    static preventScrollLeaking(event) {
        const container = event.currentTarget;

        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const height = container.clientHeight;
        const delta = Math.abs(event.originalEvent.wheelDelta);
        const up = event.originalEvent.wheelDelta > 0;

        if (!up && delta > scrollHeight - height - scrollTop) {
            container.scrollTop = scrollHeight;
            event.preventDefault();

        } else if (up && delta > scrollTop) {
            container.scrollTop = 0;
            event.preventDefault();
        }
    }

    static get SCROLL_OFFSET() {
        return {
            MOBILE: 16,
            DESKTOP: 32
        };
    }

    static disableBodyScrolling() {
        $('body').css({
            position: 'fixed',
            top: `-${($('html').scrollTop() || $('body').scrollTop())}px`,
            width: '100%'
        });
    }

    static enableBodyScrolling() {
        $('body').css({
            position: 'static'
        });

        setTimeout(() => {
            $('body').removeAttr('style');
        }, 100);

        const scrollTopNumber = $('body').css('top').match(/\d+/);
        if (scrollTopNumber) {
            $('html, body').scrollTop(scrollTopNumber[0]);
        }
    }
}
