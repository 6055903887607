import KeyHelper from '../helpers/key';


function init() {
    $(document).on('keydown', 'div[tabindex]', (event) => {
        if (event.currentTarget === event.target) {
            if (KeyHelper.isReturn(event) || KeyHelper.isSpace(event)) {
                $(event.currentTarget).trigger('click');

                event.preventDefault();
                event.stopPropagation();
            }
        }
    });

    const $selectors = 'a, [tabindex]:not(input, textarea)';

    $(document).on('mouseup', $selectors, (event) => {
        $(event.currentTarget).blur();
    });
}


const hooks = {
    init
};


export { hooks };
