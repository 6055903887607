export default class KeyHelper {
    static isReturn(event) {
        return event.which === KeyHelper.RETURN;
    }

    static isTab(event) {
        return event.which === KeyHelper.TAB;
    }

    static isShift(event) {
        return event.which === KeyHelper.SHIFT;
    }

    static isEscape(event) {
        return event.which === KeyHelper.ESCAPE;
    }

    static isSpace(event) {
        return event.which === KeyHelper.SPACE;
    }

    static isDigit(event) {
        return event.which >= 48 && event.which <= 57;
    }

    static isBackspace(event) {
        return event.which === KeyHelper.BACKSPACE;
    }

    static getCharacter(event) {
        return String.fromCharCode(event.which);
    }

    static isDown(event) {
        return event.which === KeyHelper.DOWN;
    }

    static isUp(event) {
        return event.which === KeyHelper.UP;
    }

    static get RETURN() {
        return 13;
    }

    static get TAB() {
        return 9;
    }

    static get SHIFT() {
        return 16;
    }

    static get ESCAPE() {
        return 27;
    }

    static get SPACE() {
        return 32;
    }

    static get BACKSPACE() {
        return 8;
    }

    static get DOWN() {
        return 40;
    }

    static get UP() {
        return 38;
    }
}
