import has from 'lodash/has';
import once from 'lodash/once';


const TRACKING_TIMEOUT = 1000;
export default class TrackingHandler {
    static track({ event, data }) {
        const hasCallback = typeof data.eventCallback === 'function';

        if (hasCallback) {
            data.eventCallback = once(data.eventCallback);
            setTimeout(data.eventCallback, TRACKING_TIMEOUT);
        }

        if (event) {
            event.preventDefault();
        }

        if (has(window, 'dataLayer.push')) {
            window.dataLayer.push(data);

        } else {
            if (hasCallback) {
                data.eventCallback();
            }
        }

        return this;
    }
}
