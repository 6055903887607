let instance;
export default class ScriptHelper {
    constructor() {
        if (!instance) {
            instance = this;

            this.promises = new Map();
        }

        return instance;
    }

    registerScript(url) {
        if (this.promises.has(url)) {
            return this.promises.get(url);
        }

        const promise = this._loadScript(url);

        this.promises.set(url, promise);

        return promise;
    }

    _loadScript(url) {
        const promise = new Promise((resolve) => {
            const element = document.createElement('script');
            element.async = true;

            element.addEventListener('load', () => resolve(true));
            element.addEventListener('error', () => {
                resolve(false);

                // eslint-disable-next-line
                console.error(`Failed to load the script: ${url}.`);
            });


            element.src = url;
            document.body.appendChild(element);
        });

        return promise;
    }
}
