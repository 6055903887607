export default class InterceptorMessage {
    constructor({ shouldIntercept, title = null, message = null }) {
        Object.assign(this, { shouldIntercept, title, message });
    }

    getMessageContent() {
        return {
            title: this.title,
            message: this.message
        };
    }
}
