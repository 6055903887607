/* global __webpack_public_path__, ENTRY false */

import has from 'lodash/has';

import { History, modules } from 'commons';
import { AjaxHandler, MessageQueue, ComponentLoader } from 'sets/shop';


function initItems(items, hook) {
    items
        .filter(item => has(item, `hooks.${hook}`))
        .forEach(item => item.hooks[hook]());
}

__webpack_public_path__ = window.path + '/assets/'; // eslint-disable-line no-native-reassign, no-global-assign

async function init() {
    window.kfz24 = window.kfz24 || {};

    const body = document.querySelector('body');
    body.messageQueue = body.messageQueue || new MessageQueue();

    await ComponentLoader.loadAndInitAll();

    initItems(modules, 'init');

    new AjaxHandler({ messageQueue: body.messageQueue });

    const history = new History();
    history.updateHistory();
}

$(() => {

    window.flags = {
        is3DsEnabled: true
    };

    if (window.store) {
        if (window.seoLinksReady) {
            init();
        } else {
            window.addEventListener('SEO_LINKS__READY', init);
        }
    } else {
        init();
    }
});
