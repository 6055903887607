import has from 'lodash/has';

import { EventHub } from 'commons';
import { AjaxHandler, ComponentLoader } from 'sets/shop';


export default class CarisTrigger {

    static async showGarageDialog(url, carTypeNumber, vehicle, updateVehicleList) {
        const TheDialog = await ComponentLoader.loadByName('TheDialog');
        const dialog = await TheDialog.createFromTemplate({
            $root: this.$element,
            templateVersion: 'garage-form',
            title: vehicle.data.title,
            content: vehicle.html,
            callbacks: {
                accept: {
                    callback: CarisTrigger._editAcceptCallback.bind(this, url, carTypeNumber, updateVehicleList),
                    parameters: {
                        action: '',
                        close: true
                    }
                },
                cancel: {
                    parameters: {
                        action: '',
                        close: true
                    }
                }
            }
        });

        const $element = dialog.getContentElements();
        $element.trigger(AjaxHandler.INIT_COMPONENT, { $element });
    }

    static _editAcceptCallback(url, carTypeNumber, updateVehicleList, context) {

        const eventHub = new EventHub();
        const { dialog } = context;
        const data = dialog.getFormData();

        const request = $.post(`${url}${carTypeNumber}`, data);

        request.then(async (response) => {
            if (has(response, 'partials.vehicle')) {
                CarisTrigger.showGarageDialog(url, carTypeNumber, response.partials.vehicle, updateVehicleList);
            } else {
                if (updateVehicleList) {
                    const VehicleTile = await ComponentLoader.loadByName('VehicleTile');
                    eventHub.broadcast(VehicleTile.VEHICLE_UPDATED);

                }
            }
        });
    }

    static getGarageForm({ url, carTypeNumber, updateVehicleList = false }) {

        const request = $.get(`${url}${carTypeNumber}`);
        request.done((response) => {
            if (has(response, 'partials.vehicle')) {
                CarisTrigger.showGarageDialog(url, carTypeNumber, response.partials.vehicle, updateVehicleList);
            }
        });
    }
}
